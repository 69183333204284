<template>
  <div class="w-full py-12">
    <div class="w-full max-w-xl mx-auto px-6">
      <div class="mt-12 leading-none flex flex-col items-center justify-center">
        <h2 class="font-bold text-5xl">Choose Interview Dates</h2>

        <h3 class="subtitle mt-6 text-gray-500 text-sm">Please choose up to three dates.</h3>
      </div>
      <div class="my-16">
        <div class="relative">
          <div v-if="! hasSlots" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-200 bg-opacity-75">
            <div class="">
              <loading-icon class="h-10 w-10"/>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <div class="w-16 text-right">
              <button v-if="! month.startOf('month').isBefore(now)" @click.prevent="prevMonth()" class="mr-4 w-auto btn m-0 px-2 py-0 text-gray-600">
                <i class="fas fa-chevron-left"></i>
              </button>
            </div>
            <div class="flex justify-center">
              <span class="text-gray-500 text-xl">{{ this.month.format('MMMM YYYY') }}</span>
            </div>
            <div class="w-16 ml-4">
              <button @click.prevent="nextMonth()" class="btn w-auto m-0 px-2 py-0 text-gray-600">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="grid grid-cols-7 mt-4">
            <div class="text-sm text-gray-500 text-center">Sun</div>
            <div class="text-sm text-gray-500 text-center">Mon</div>
            <div class="text-sm text-gray-500 text-center">Tue</div>
            <div class="text-sm text-gray-500 text-center">Wen</div>
            <div class="text-sm text-gray-500 text-center">Thu</div>
            <div class="text-sm text-gray-500 text-center">Fri</div>
            <div class="text-sm text-gray-500 text-center">Sat</div>
          </div>
          <div class="grid grid-cols-7 gap-3 mt-3">
            <div class="relative pb-full" v-for="day in monthRange.by('day')" :key="`day-${day.format('YYYY-MM-DD')}`">
              <div v-if="day.isSame(month, 'month')" class="absolute inset-0">
                <template v-if="hasAvailabilityFor(day)">
                  <div class="relative w-full h-full rounded-full bg-white border flex items-center justify-center overflow-hidden">
                    <availability-slots
                      :day="day"
                      :slots="slots[day.format('YYYY-MM-DD')]"
                      :key="`day-${day.format('YYYY-MM-DD')}`"
                      :selected-slots-prop="selectedSlots"
                      @updated-slots="updateSelectedSlots"
                    >
                      <span class="flex flex-col leading-none">
                        <span class="text-sm">{{ day.format('D') }}</span>
                        <span v-if="dayHasSelectedSlots(day.format('YYYY-MM-DD'))" class="absolute bottom-0 mb-1 inset-x-0">
                          <span
                            v-for="slot in daySelectedSlots(day.format('YYYY-MM-DD'))"
                            class="text-pink-500 text-2xl"
                            :key="`slot-${slot.datetime}`"
                          >&bull;</span>
                        </span>
                      </span>
                    </availability-slots>
                  </div>
                </template>
                <template v-else>
                  <div class="w-full h-full rounded-full flex items-center justify-center overflow-hidden">
                    <span class="text-sm">{{ day.format('D') }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <select class="form-select" id="timezone" v-model="timezone" @change="refreshSlots()">
            <option
              v-for="timezone in timezones"
              :key="timezone.timezone"
              :value="timezone.timezone"
            >
              {{ timezone.label }}
            </option>
          </select>
        </div>
        <div class="mt-12">
          <h3 class="font-bold text-3xl text-center">Your picked dates</h3>
          <div class="mt-8 grid grid-cols-3 gap-8">
            <div v-for="(slot, index) in 3" class="relative pb-full" :key="`slot-${index}`">
              <div v-if="selectedSlots[index]" class="absolute inset-0 border rounded shadow bg-white flex flex-col items-center justify-center leading-none">
                <div class="text-lg text-gray-600">{{ moment(selectedSlots[index].datetime).format('MMMM D') }}</div>
                <div class="mt-4 font-bold text-2xl">{{ moment(selectedSlots[index].datetime).format('HH:mm') }}</div>
                <div class="absolute bottom-0 mb-4">
                  <button @click.prevent="removeSlot(selectedSlots[index])" class="text-xs text-pink-400 hover:text-pink-500 py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                    remove
                  </button>
                </div>
              </div>
              <div v-else class="absolute inset-0 border-4 border-dashed rounded">

              </div>
            </div>
          </div>
        </div>

        <div class="mt-16">
          <button
            class="btn bg-gray-600 hover:bg-gray-700 flex items-center justify-center transition-colors duration-300 ease-in-out"
            type="submit"
          >
            <span v-if="loading">
              <loading-icon class="h-5 w-5 mr-4"/>
            </span>
            <span v-else>Save and continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import Moment from 'moment'
  import { extendMoment } from 'moment-range'
  import timezones from '@/mixins/timezones'
  import AvailabilitySlots from '@/components/AvailabilitySlots'
  const moment = extendMoment(Moment)

  export default {
    name: 'GuestAvailability',

    mixins: [timezones],

    components: {
      AvailabilitySlots,
    },

    data() {
      return {
        moment: moment,
        now: moment(),
        loading: false,
        availability: null,
        timezone: null,
        selectedSlots: [],
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      guest() {
        return this.$store.getters['guest/getGuest']
      },

      settings() {
        return this.$store.getters['guest/availability/settings']
      },

      slots() {
        return this.$store.getters['guest/availability/slots']
      },

      hasSlots() {
        return !! Object.keys(this.slots).length
      },

      month() {
        return this.$route.query.month
          ? moment(this.$route.query.month, 'YYYY-MM')
          : moment()
      },

      monthRange() {
        let start = this.month.clone().startOf('month').startOf('week')
        let end = this.month.clone().endOf('month').endOf('week')

        return moment.range(start, end)
      },
    },

    watch: {
      // eslint-disable-next-line
      $route(to, from) {
        this.getViewData()
      },

      user(user) {
        if (user.timezone) {
          this.timezone = user.timezone
        }
      },
    },

    methods: {
      async getViewData() {
        if (! this.$route.params.guestId) {
          return
        }

        await this.$store.dispatch('guest/fetchGuest', { id: this.$route.params.guestId})
        await this.$store.dispatch('guest/availability/fetch', { guestId: this.$route.params.guestId})
        await this.getSlots()
      },

      async getSlots() {
        this.$store.dispatch('guest/availability/fetchSlots', {
          guestId: this.$route.params.guestId,
          month: this.$route.query.month || this.month.format('YYYY-MM'),
          timezone: this.timezone,
        })
      },

      hasAvailabilityFor(day) {
        let dayString = day.format('YYYY-MM-DD')

        if (! this.slots.hasOwnProperty(dayString)) {
          return false;
        }

        return Object.values(this.slots[dayString]).some(slot => slot.status == 'available')
      },

      guessTimezone() {
        let zone = moment().utcOffset()

        if (moment().isDST()) {
          zone -= 60
        }

        let timezone = this.timezones.find(timezone => timezone.offset == (zone / 60))

        if (timezone) {
          this.timezone = timezone.timezone
        }
      },

      clearSlots() {
        this.$store.commit('guest/availability/setSlots', {})
      },

      refreshSlots() {
        this.clearSlots()
        this.getSlots()
      },

      prevMonth() {
        this.clearSlots()
        this.$router.push({
          name: 'guest-availability',
          params: {
            guestId: this.$route.params.guestId
          },
          query: {
            month: this.month.clone().subtract(1, 'month').format('YYYY-MM'),
            timezone: this.timezone,
          }
        })
      },

      nextMonth() {
        this.clearSlots()
        this.$router.push({
          name: 'guest-availability',
          params: {
            guestId: this.$route.params.guestId
          },
          query: {
            month: this.month.clone().add(1, 'month').format('YYYY-MM'),
            timezone: this.timezone,
          }
        })
      },

      slotIsSelected(slot) {
        return this.selectedSlots.find(s => s.datetime == slot.datetime)
      },

      updateSelectedSlots(slots) {
        if (slots.length === 3) {
          this.selectedSlots = [...slots]
          return
        }

        slots.forEach(slot => {
          if (this.slotIsSelected(slot)) {
            return
          }

          if (this.selectedSlots.length == 3) {
            this.selectedSlots.shift()
          }

          this.selectedSlots.push(slot)
        })
      },

      removeSlot(slot) {
        let index = this.selectedSlots.indexOf(slot)
        this.selectedSlots.splice(index, 1)
      },

      dayHasSelectedSlots(dateString) {
        return this.daySelectedSlots(dateString).length
      },

      daySelectedSlots(dateString) {
        return this.selectedSlots.filter(slot => slot.date == dateString)
      }
    },

    created() {
      this.guessTimezone()
      this.getViewData()
    },
  }
</script>
