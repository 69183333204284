<template>
  <div class="w-full h-full">
    <button class="w-full h-full hover:bg-indigo-100 focus:outline-none" @click.prevent="showPicker = true">
      <slot />
    </button>

    <div v-if="showPicker" @click="showPicker = false" class="fixed z-50 inset-0 bg-gray-800 bg-opacity-25 pt-16">
      <div @click.stop class="mx-auto max-w-lg w-full bg-white shadow-lg p-8 rounded overflow-hidden">
        <div class="relative flex items-center justify-center">
          <h3 class="text-2xl">{{ day.format('dddd, MMMM D') }}</h3>
          <button @click.prevent="closePicker()" class="absolute right-0 text-indigo-500 btn mt-0 mb-0 w-auto px-2 py-2">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="mt-8">
          <div class="grid grid-cols-2 gap-6">
            <div v-for="(slot, index) in slots" :key="`slot-${index}`" class="flex">
              <button
                @click.prevent="pickSlot(slot)"
                class="transition-colors duration-300 ease-in-out h-16 w-full bg-gray-200 border rounded flex items-center justify-center shadow hover:bg-indigo-100 focus:outline-none focus:shadow-outline"
                :class="{'bg-pink-500 text-white hover:bg-pink-600': slotIsSelected(slot)}">
                {{ parseTime(slot.datetime) }}
              </button>
            </div>
          </div>
        </div>

        <div class="mt-12">
         <button
            class="btn bg-gray-600 hover:bg-gray-700 flex items-center justify-center transition-colors duration-300 ease-in-out"
            type="submit"
            :class="{'bg-opacity-50': ! selectedSlots.length}"
            :disabled="! selectedSlots.length"
            @click.prevent="selectSlots"
          >
            <span>
              Pick <span v-if="selectedSlots.length">{{ slotsText }}</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import pluralize from 'pluralize'
  export default {
    props: {
      day: Object,
      slots: Array,
      selectedSlotsProp: Array,
    },

    data() {
      return {
        showPicker: false,
        selectedSlots: [],
      }
    },

    computed: {
      slotsText() {
        return `${this.selectedSlots.length} ${pluralize('slot', this.selectedSlots.length)}`
      }
    },

    watch: {
      showPicker(showPicker) {
        document.body.classList.toggle('overflow-hidden', showPicker)

        if (showPicker) {
          this.parseSelectedSlots()
        }
      },
    },

    methods: {
      closePicker() {
        this.showPicker = false
        this.selectedSlots.length = 0
      },

      parseTime(datetime) {
        return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('h:mm a')
      },

      selectSlots() {
        this.$emit('updated-slots', this.selectedSlots)
        this.closePicker()
      },

      slotIsSelected(slot) {
        return this.selectedSlots.find(s => s.datetime == slot.datetime)
      },

      pickSlot(slot) {
        if (this.slotIsSelected(slot)) {
          let index = this.selectedSlots.indexOf(slot)
          this.selectedSlots.splice(index, 1)
          return
        }

        if (this.selectedSlots.length == 3) {
          this.selectedSlots.shift()
        }

        this.selectedSlots.push(slot)
      },

      parseSelectedSlots() {
        if (! this.selectedSlotsProp.length) {
          return
        }

        this.selectedSlotsProp.forEach(slot => {
          if (this.slots.includes(slot)) {
            this.pickSlot(slot)
          }
        })
      }
    }

  }
</script>
